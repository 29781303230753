import { useLocation } from "react-router-dom";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import messages from "../i18n/i18n";
import { useDispatch, useSelector } from "react-redux";
import { setLangAction } from "../store/actions/i18n_actions";
import _ from "lodash";
import { MenuIcon } from "./icons";
import { AppState } from "../store/store";
import { AuthState } from "../store/reducers/auth_reducer";
import { Avatar } from "@material-ui/core";
import { httpDelete } from "../repsitory/generic_repository";
import { APP_BASE_URL, BASE_URL } from "../index";

export function Header(props: {
  filteredItems?: string[];
  maxLength?: number;
}) {
  const l = useLocation();
  const auth = useSelector((a: AppState) => a.auth);
  const intl = useIntl();

  const location = l.pathname
    .substring(1, l.pathname.length)
    .replace("-", " ")
    .replace("_", " ")
    .split("/")
    .filter((r) => !(props.filteredItems ?? []).includes(r))
    .slice(0, props.maxLength ?? 1000)
    .map((s) => intl.messages[`title_${s}`]?.toString() ?? s)
    .map((s) => _.upperFirst(s))
    .map((s) => (s.length < 35 ? s : s.substring(0, 35) + ".."))
    .join("  |  ");

  const dispatch = useDispatch();
  const ref = useRef<HTMLHeadingElement>(null);

  const username = getUserName(auth).toUpperCase();

  return (
    <header
      className={
        "c-header c-header-light c-header-fixed d-flex align-items-center justify-content-between"
      }
      ref={ref}
    >
      <div className={"d-flex flex-row ml-5"}>
        <button
          className="c-header-toggler c-class-toggler d-lg-none menu-button mr-3"
          type="button"
          data-target="#sidebar"
          data-class="c-sidebar-show"
        >
          <MenuIcon />
        </button>
        <h1 className={"mb-0"}>{location || "Home"}</h1>
      </div>

      <div className={"d-flex"}>
        <div className="dropdown mr-5" style={{ marginTop: 6 }}>
          <button
            className="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {intl.locale}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {Object.keys(messages).map((l: string) => (
              <button
                key={l}
                className={"dropdown-item"}
                onClick={() => dispatch(setLangAction(l))}
              >
                {l}
              </button>
            ))}
          </div>
        </div>
        <div className={"mr-5 pt-2 ml-2 d-flex flex-column align-items-end"}>
          <h3>{username}</h3>
          <h3
            style={{ fontWeight: 300 }}
            onClick={() =>
              httpDelete("/api/v1/logout").then(() => {
                window.sessionStorage.clear();
                window.location.href = `/login`;
              })
            }
          >
            Log out
          </h3>
        </div>
      </div>
    </header>
  );
}

function getUserName(appState: AuthState) {
  if (!appState.token) return "";
  const info = atob(appState.token.split(".")[1]);
  return JSON.parse(info).preferred_username ?? "";
}
