import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { postWithoutToken } from "repsitory/generic_repository";
import { useDispatch } from "react-redux";
import { history } from "index";
import { useIntl } from "react-intl";
import { LoaderComponent } from "components/loading_widget";

const Login: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<any>(null); // Состояние для ошибки
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const loginWithoutToken = async () => {
    setIsLoading(true); // Show loader before the request
    setError(null); // Сбрасываем ошибку перед запросом
    const body = {
      data: {
        attributes: {
          username: username,
          password: password,
        },
      },
    };

    const url = "/api/v1/login_api";

    try {
      const response = await postWithoutToken(body, url);

      if (response.errors?.detail?.error == "invalid_grant") {
        console.error(response);

        setError(intl.messages["invalid_credentials"]); // Общая ошибка
        return;
      }

      const token = response.access_token;
      setToken(token);
    } catch (e) {
      console.error(e);
      setError("An unexpected error occurred. Please try again."); // Общая ошибка
    } finally {
      setIsLoading(false); // Hide loader after request completion
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    loginWithoutToken();
  };

  useEffect(() => {
    if (token) {
      dispatch({
        type: "SET_TOKEN",
        token,
      });
      history.push("/condominiums");
    }
  }, [token]);

  return (
    <Container>
      <LoginWrapper>
        {isLoading && (
          <LoaderWrapper>
            <LoaderComponent />
          </LoaderWrapper>
        )}
        <Logo>SWARM</Logo>
        <LoginBox>
          <Heading>{intl.messages["signt_in_acc"]}</Heading>
          {error && <ErrorMessage>{error}</ErrorMessage>}{" "}
          {/* Отображение ошибки */}
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <PasswordWrapper>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <PasswordToggle onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? "🚫" : "👁️"}
              </PasswordToggle>
            </PasswordWrapper>
            <ForgotPasswordContainer>
              <ForgotPasswordLink
                onClick={() => history.push("/recover_password")}
              >
                {intl.messages["forgot_password"]}
              </ForgotPasswordLink>
            </ForgotPasswordContainer>
            <SignInButton disabled={!username || !password} type="submit">
              {intl.messages["sign_in"]}
            </SignInButton>
          </Form>
        </LoginBox>
      </LoginWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #34495e;
  margin: 0;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Important for centering loader inside the login box */
  width: 480px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 30px;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 20px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the loader */
  z-index: 10; /* Make sure the loader appears above other content */
`;

const Logo = styled.h1`
  font-size: 40px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: #3498db; /* Blue color */
  margin-bottom: 50px;
  text-align: center;
  letter-spacing: 2px;
  animation: pulse 2s ease-in-out infinite; /* Applying the pulse animation with a slower duration */

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05); /* Reduced size increase to 5% */
      opacity: 0.9; /* Reduced opacity change for a softer effect */
    }
    100% {
      transform: scale(1); /* Restoring the original size */
      opacity: 1; /* Restoring the original opacity */
    }
  }
`;
const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  letter-spacing: 1px;
`;

const LoginBox = styled.div`
  width: 100%;
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: #2980b9;
  }
`;

const PasswordWrapper = styled.div`
  position: relative;
`;

const PasswordToggle = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ForgotPasswordLink = styled.a`
  font-size: 16px;
  color: #34495e;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: #1abc9c;
  }
`;

const SignInButton = styled.button`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #34495e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #3498db;
  }

  &:disabled {
    background-color: #bdc3c7; /* Светло-серый цвет для отключенной кнопки */
    cursor: not-allowed; /* Меняем курсор на запрещающий */
    color: #7f8c8d; /* Более светлый текст для disabled */
  }
`;

export default Login;
