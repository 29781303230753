import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom"; // Use useHistory for redirecting
import styled from "styled-components";
import { putWithoutToken } from "repsitory/generic_repository";
import { LoaderComponent } from "components/loading_widget";
import { useIntl } from "react-intl";
const ConfirmRegistration: React.FC = () => {
  const intl = useIntl();
  const location = useLocation(); // To get URL parameters
  const history = useHistory(); // To redirect after successful confirmation
  const [inputCode, setInputCode] = useState(""); // Code from URL or entered manually
  const [isLoading, setIsLoading] = useState(false); // To track loading state
  const [confirmationMessage, setConfirmationMessage] = useState<any>(null); // Confirmation message
  const [errorMessage, setErrorMessage] = useState<any>(null); // Error message

  // useEffect triggers when URL parameters change
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeFromUrl = queryParams.get("code"); // Get code from URL
    if (codeFromUrl) {
      setInputCode(codeFromUrl); // Pre-fill the input if code is present
      handleConfirmRegistration(codeFromUrl); // Automatically send the request
    }
  }, [location.search]);

  // Function to send request for registration confirmation
  const handleConfirmRegistration = async (code: string) => {
    setIsLoading(true); // Show the spinner
    const body = { code };
    const url = "/api/v1/user/confirm"; // URL for confirmation

    try {
      const response = await putWithoutToken(body, url); // Send the request

      if (response === "success") {
        setConfirmationMessage(intl.messages["registration_confirmed"]); // Success message
        setErrorMessage(""); // Clear any error message
       /*  setTimeout(() => {
          history.push("/login"); // Redirect to login page
        }, 2000); */ // Redirect after a short delay
      } else {
        setErrorMessage(intl.messages["invalid_code"]); // Invalid code error
      }
    } catch (error) {
      console.error("Error confirming registration:", error);
      setErrorMessage("An error occurred while confirming your registration."); // Error during request
    } finally {
      setIsLoading(false); // Hide the spinner
    }
  };

  // Handle form submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputCode) {
      handleConfirmRegistration(inputCode); // Send request on form submit
    }
  };

  // Handle back to login button click
  const handleBackToLogin = () => {
    history.push("/login"); // Redirect to login page
  };

  return (
    <Container>
      <ConfirmRegistrationWrapper>
        <Logo>SWARM</Logo>
        <Heading>{intl.messages["confirm_registration"]}</Heading>

        {isLoading ? (
          <LoaderContainer>
            <LoaderComponent /> {/* Show the spinner during loading */}
          </LoaderContainer>
        ) : confirmationMessage ? (
          <ConfirmationMessage>{confirmationMessage}</ConfirmationMessage> // Success message after confirmation
        ) : (
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder={intl.messages["enter_code"]?.toString()}
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
              autoFocus
            />
            <VerifyButton disabled={!inputCode} type="submit">
              {intl.messages["verify_code"]}
            </VerifyButton>
          </Form>
        )}

        {/* Display error message below the form */}
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

        {/* Button for redirecting to login */}
        {confirmationMessage && (
          <BackToLoginButton onClick={handleBackToLogin}>
            {intl.messages["back_to_login"]}
          </BackToLoginButton>
        )}
      </ConfirmRegistrationWrapper>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #34495e;
  margin: 0;
`;

const ConfirmRegistrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  word-wrap: break-word; /* Allow text to wrap to the next line if it's too long */

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 30px;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 20px;
  }
`;

const Logo = styled.h1`
  font-size: 40px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: #3498db; /* Blue color */
  margin-bottom: 50px;
  text-align: center;
  letter-spacing: 2px;
  animation: pulse 2s ease-in-out infinite; /* Applying the pulse animation with a slower duration */

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05); /* Reduced size increase to 5% */
      opacity: 0.9; /* Reduced opacity change for a softer effect */
    }
    100% {
      transform: scale(1); /* Restoring the original size */
      opacity: 1; /* Restoring the original opacity */
    }
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  letter-spacing: 1px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: #2980b9;
  }
`;

const VerifyButton = styled.button`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #34495e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #3498db;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
`;

const ConfirmationMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  color: green;
  font-size: 18px;
  font-weight: bold;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BackToLoginButton = styled.button`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #2980b9;
  }
`;

export default ConfirmRegistration;
