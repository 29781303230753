import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ErrorComponent } from "../../../components/error_component";
import {
  TrashIcon,
  MoreVerticalIcon,
  SelectedIcon,
  NotSelectedIcon,
} from "../../../components/icons";
import { PaginatedListComponent } from "../../../components/paginated_list/paginated_list_component";
import { User } from "../../../models/user";
import {
  deleteUser,
  fetchPaginatedUsers,
} from "../../../repsitory/user_repository";
import { history } from "../../../index";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { FilterParam } from "../../../models/barbagli/filter_order";

export function BarbagliUsersPage() {
  const intl = useIntl();
  const [error, setError] = useState("");
  const [filters, setFilters] = useState<FilterParam[]>([]);
  const [initialPage, setInitialPage] = useState(0);
  return (
    <div>
      <div className={"row"}>
        <div className={"col-md-12 ellipsis"}>
          <SearchBar
            filters={filters}
            avaliableFilters={[
              "username",
              "email",
              "first_name",
              "last_name",
              "type",
            ]}
            filterNamePostProcessor={(id) =>
              intl.messages[id]?.toString() ?? id
            }
            onFiltersChange={setFilters}
          />
          <PaginatedListComponent
            key={JSON.stringify(filters)}
            headers={[
              "username",
              "email",
              "first_name",
              "last_name",
              "type",
              "emailVerified",
              "actions",
            ].map((l) => (intl.messages[l] ?? l).toString())}
            rowBuilder={(u: User) => [
              <p>{u.username}</p>,
              <p>{u.email || "--"}</p>,
              <p>{u.firstName || "--"}</p>,
              <p>{u.lastName || "--"}</p>,
              <p>{u.type || "--"}</p>,
              <p>{u.emailVerified ? <SelectedIcon /> : <NotSelectedIcon />}</p>,
              // <p>{u.condominium || "--"}</p>,
              // <p>{u.subCondominium || "--"}</p>,
              // <p>{u.immobile || "--"}</p>,
              <div style={{ marginTop: "-6px" }} className={"d-flex"}>
                <button
                  className={"icon-button"}
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmAlert({
                      title: intl.messages["confirm"].toString(),
                      message:
                        intl.messages["this_action_cant_be_undone"].toString(),
                      buttons: [
                        {
                          label: "Yes",
                          onClick: async () => {
                            setError(await deleteUser(u.userId ?? u.id!));
                            setInitialPage(0);
                            setInitialPage(1);
                          },
                        },
                        {
                          label: "No",
                          onClick: () => {},
                        },
                      ],
                    });
                  }}
                >
                  <TrashIcon />
                </button>
                <button className={"icon-button"}>
                  <MoreVerticalIcon />
                </button>
              </div>,
            ]}
            fetchCollection={fetchPaginatedUsers(
              `/api/v1/user?${filters
                .map((f) => `${f.column}=${f.value}`)
                .join("&")}`
            )}
            perPage={16}
            onTap={(u: User) => {
              history.push(`/users/${u.username}/edit`, u);
            }}
            initialPage={initialPage}
          />
        </div>
      </div>
      {error !== "" && <ErrorComponent message={error} />}
      <div className={"d-flex flex-row-reverse"}>
        <Link
          to={"/users/new"}
          style={{ textDecoration: "none", color: "#fff" }}
        >
          <div className={"p3 primary-button"}>{intl.messages["add_user"]}</div>
        </Link>
      </div>
    </div>
  );
}
