import React, { useState } from "react";
import { ConfigurationSpec, ConfigurationValue } from "../../models/configuration";
import './configuration_editor.css'
import { ClearIcon } from "../icons";
import { Divider } from "../divider";
import { EditableRow } from "../InfoRow";
import { updateConfigurationValues, validateValues } from "./configuration_editor_vm";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { defaultFormat } from "moment";
import { formatHeader } from "../../utils/object_formatting";
import { Input, InputArea } from "components/barbagli/text_input";
import { debug } from "console";

type Props = {
  configurationSpecs: ConfigurationSpec[];
  configurationValues?: ConfigurationValue[];
  onSave: (c: ConfigurationValue[]) => void;
  onClose: () => void;
  title: string;
  saveButtonText?: string,
  intl: Record<string, string>
}

export function ConfigurationEditor(props: Props) {

  const [state, setState] = useState<ConfigurationValue[]>(props.configurationValues ?? [])
  const [errors, setErrors] = useState<string[]>([]);

  const validate = (values: ConfigurationValue[]) => {
    return props.configurationSpecs.flatMap((s) => validateValues(values, s, props.intl))
  }

  return <div className={"configuration-editor"}>
    <div className={"row d-flex flex-row justify-content-between"}>
      <h3 className={"dashboard-editor-title"}>{props.title.toUpperCase()}</h3>
      <button className={"icon-button dashboard-editor-icon"} onClick={props.onClose}><ClearIcon /></button>
    </div>
    <Divider className={"mt-1 mb-3"} />
    {
      // props.configurationSpecs.map(configurationSpec => <EditableRow
      //   title={`${formatHeader(configurationSpec.name)} (${configurationSpec.type})`}
      //   value={_.first(state.filter((cv => cv.name === configurationSpec.name)))?.value?.toString() ?? ""}
      //   onEdit={(value) => {
      //     const values = updateConfigurationValues(state, configurationSpec, value, props.intl);
      //     setState(values);
      //   }}
      //   key={configurationSpec.name}
      // />)
      props.configurationSpecs.map(configurationSpec =>
        <ConfigurationParamEditor
          spec={configurationSpec}
          value={_.first(state.filter((cv => cv.name === configurationSpec.name)))!}
          onChange={(value) => {
            // console.log(value);
            debugger
            const values = updateConfigurationValues(state, configurationSpec, value, props.intl);
            setState(values);
          }}
        />)
    }

    <div className={"d-flex flex-row-reverse"}>
      <button className={"p3 primary-button"} onClick={() => {
        const errors = validate(state);
        setErrors(errors);
        if (errors.length === 0) props.onSave(state)
      }}>
        {props.saveButtonText ?? props.intl["save"]}
      </button>
      <button
        onClick={props.onClose}
        className={"p3 outline-button mr-3"}
      >
        {'Cancel'}
      </button>
      <p className={"p-2 mr-5"}>{errors.join(", ")}</p>
    </div>

  </div>;
}

export function ConfigurationParamEditor(props: { spec: ConfigurationSpec, value: ConfigurationValue, onChange: (cv: ConfigurationValue) => void }) {
  switch (props.spec.type) {
    case "void": return <></>
    case "{array, map}": return <InputArea
      big
      disabled={false}
      value={
        //  @ts-ignore
        (props?.value?.value ?? []).join("\n")
      }
      onChange={
        (e) => {
          const v: string = e?.target?.value ?? "";
          // console.log(v)
          props.onChange({ name: props.spec.name, value: v.split("\n") })
        }
      }
    />
    case "{array, string}": return <InputArea
      big
      disabled={false}
      value={
        //  @ts-ignore
        (props?.value?.value ?? []).join("\n")
      }
      onChange={
        (e) => {
          const v: string = e?.target?.value ?? "";
          // console.log(v)
          props.onChange({ name: props.spec.name, value: v.split("\n") })
        }
      }
    />
    default: return <EditableRow
      title={`${formatHeader(props.spec.name)} (${props.spec.type})`}
      value={props?.value?.value?.toString() ?? ""}
      onEdit={(value) => {
        props.onChange({ name: props.spec.name, value: value })
      }}
      key={props.spec.name}
    />
  }
}

export function showConfigurationEditor(props: Props) {
  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({ onClose }) =>
      <ConfigurationEditor
        configurationSpecs={props.configurationSpecs}
        configurationValues={props.configurationValues}
        saveButtonText={props.saveButtonText}
        onClose={() => {
          props.onClose();
          onClose();
        }}
        onSave={(v) => { props.onSave(v); onClose(); }}
        title={props.title}
        intl={props.intl}
      />
  });
}
