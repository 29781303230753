import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { putWithoutToken } from "repsitory/generic_repository";
import { LoaderComponent } from "components/loading_widget"; // Assuming you already have this component
import { useIntl } from "react-intl";

const SetNewPassword: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const [inputCode, setInputCode] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false); // Default to false to show form initially
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeFromUrl = queryParams.get("code");
    if (codeFromUrl && !inputCode && firstRender) {
      setInputCode(codeFromUrl);
      setFirstRender(false);
    }
  }, [location.search, inputCode]);

  const handleChangePassword = async () => {
    setIsLoading(true); // Start loading
    const body = {
      code: inputCode,
      password: password,
      password_confirmation: confirmPassword,
    };

    const url = "/api/v1/user/reset_password"; // Endpoint for changing the password
    try {
      const response = await putWithoutToken(body, url); // Send the PUT request
      console.log("response", response);

      if (response === "success") {
        setIsPasswordUpdated(true); // Show success message
        setErrorMessage(""); // Clear any previous error
      } else if (response.errors && response.errors.detail === "invalid_code") {
        setErrorMessage(intl.messages["invalid_code"]);
      } else {
        setErrorMessage(
          "An error occurred while resetting the password. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error while changing password:", error);
      setErrorMessage(
        "An error occurred while resetting the password. Please try again later."
      );
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isPasswordMatch) {
      handleChangePassword();
    } else {
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setIsPasswordMatch(password === newConfirmPassword);
  };

  const isFormValid =
    inputCode && password && confirmPassword && isPasswordMatch;

  const handleBackToLogin = () => {
    history.push("/login");
  };

  return (
    <Container>
      <SetPasswordWrapper>
        <Logo>SWARM</Logo>
        <Heading>
          {!isPasswordUpdated && intl.messages["set_new_password"]}
        </Heading>

        {isLoading ? (
          <LoaderContainer>
            <LoaderComponent />
          </LoaderContainer>
        ) : isPasswordUpdated ? (
          <SuccessMessage>
            {intl.messages["password_success"]}
            <BackButtonWrapper>
              <BackButton onClick={handleBackToLogin}>
                {intl.messages["back_to_login"]}
              </BackButton>
            </BackButtonWrapper>
          </SuccessMessage>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder={intl.messages["enter_code"]?.toString()}
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
            />
            <PasswordWrapper>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <PasswordToggle onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? "🚫" : "👁️"}
              </PasswordToggle>
            </PasswordWrapper>
            <PasswordWrapper>
              <Input
                type={showConfirmPassword ? "text" : "password"}
                placeholder={intl.messages["confirm_password"]?.toString()}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <PasswordToggle
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                {showConfirmPassword ? "🚫" : "👁️"}
              </PasswordToggle>
            </PasswordWrapper>

            {!isPasswordMatch && (
              <ErrorText>{intl.messages["passwords_do_not_match"]}</ErrorText>
            )}
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

            <ConfirmButton type="submit" disabled={!isFormValid}>
              {intl.messages["confirm2"]}
            </ConfirmButton>
          </Form>
        )}
      </SetPasswordWrapper>
    </Container>
  );
};

// Add a container for the loader to center it
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #34495e;
  margin: 0;
`;

const SetPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 30px;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 20px;
  }
`;

const Logo = styled.h1`
  font-size: 40px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: #3498db;
  margin-bottom: 50px;
  text-align: center;
  letter-spacing: 2px;
  animation: pulse 2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: #2980b9;
  }
`;

const PasswordWrapper = styled.div`
  position: relative;
`;

const PasswordToggle = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
`;

const ConfirmButton = styled.button`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #34495e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #3498db;
  }

  &:disabled {
    background-color: #7f8c8d;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
`;

const SuccessMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  color: green;
  font-size: 18px;
  font-weight: bold;
`;

const BackButtonWrapper = styled.div`
  margin-top: 20px;
`;

const BackButton = styled.button`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #2980b9;
  }
`;

export default SetNewPassword;
