import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { animated, interpolate, useSpring } from "react-spring";
import { on } from "process";
import { IconButton, Row } from "./common";
import { Collapsed, Expanded } from "./icons";
import { LoaderComponent } from "../loading_widget";

export type TreeModel = {
    expanded?: boolean
    id: string,
    children: TreeModel[]
}

export type Props = {
    model: TreeModel,
    level?: number,
    rowBuilder: (id: string) => React.ReactElement
    onExpandClick: (id: string, expanded: boolean, fromIcon: boolean) => void
    expandedNodes: string[],
}

const RowContainer = styled.div<{ level: number }>`
    margin-left: ${(props) => props.level * 0.5}rem;
    display: flex;
    position: relative;
    flex-direction: column;
`

const H24 = styled.div`
    height: ${(_) => row_height}px;
`

const row_height = 32;

function depth(tm: TreeModel): number {
    if (tm.children.length == 0) return row_height;
    if (!(tm.expanded ?? true)) return row_height;
    return (tm.children.map(depth)).reduce((a, b) => a + b) + row_height;
};

export function TreeView(props: Props) {

    const isExpanded = props.expandedNodes.includes(props.model.id);

    const [loading, setLoading] = useState(false);
    const onClick = useMemo(() => {
        return async (id: string, expanded: boolean, fromIcon: boolean) => {
            setLoading(true);
            const r = await props.onExpandClick(id, expanded, fromIcon);
            setLoading(false);
            return r;
        }
    }, [loading])

    return <RowContainer level={props.level ?? 0}>
        {loading && <div style={{ position: "absolute", right: 8, top: -8, }}>
            <LoaderComponent color="blue" width={32} height={32} />
        </div>}
        <div>
            <Row>
                <IconButton onClick={() => {
                    onClick(props.model.id!, !isExpanded, true);
                }}>
                    {isExpanded && <div style={{ marginBottom: 12 }}><Collapsed /></div>}
                    {!isExpanded && <div style={{ marginBottom: 12 }}><Expanded /></div>}
                </IconButton>
                <H24
                    onClick={() => onClick(props.model.id, !isExpanded, false)}
                >{props.rowBuilder(props.model.id)}</H24>

            </Row>

            {
                isExpanded && props.model.children.map((tm, i) =>
                    <TreeView
                        model={tm}
                        expandedNodes={props.expandedNodes}
                        onExpandClick={onClick}
                        level={(props.level ?? 0) + 1}
                        rowBuilder={props.rowBuilder}
                    />
                )
            }
        </div>
    </RowContainer>
}