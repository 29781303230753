import { TreeModel } from "../../../components/barbagli/tree";
import { emptyGateway } from "../../../models/gateway";
import { emptyMeter } from "../../../models/meter";
import { v1 } from "uuid";
import { Condominium, CondominiumNode, CondominiumNodeData, CondominiumNodeType, CondominiumTab } from "../../../models/barbagli/condominium";
import { first } from "../../../utils/deep_copy";
import { coordinatesFromAddress } from "../../../service/address_from_coordinates";
import { geoJsonPointFromCoordinates } from "../../../models/geo_json";


export async function geolocate(condominium: Condominium, onEdit: (c: Condominium) => void, intl: Record<string, string>, alert: any, loading: React.Dispatch<React.SetStateAction<boolean>>) {
    let errors: string[] = [];

    if (!condominium.data.address || condominium.data.address == "") errors.push(intl["geocode_address_void_error"]);
    if (!condominium.data.postalCode || condominium.data.postalCode == "") errors.push(intl["geocode_postal_code_void_error"]);
    if (!condominium.data.city || condominium.data.city == "") errors.push(intl["geocode_city_void_error"]);

    if (errors.length != 0) return alert.error(errors);
    loading(true);
    const result = await coordinatesFromAddress({ country: "italy", address: condominium.data.address, city: condominium.data.city, postalcode: condominium.data.postalCode })

    if (typeof result == "string" || result.length == 0) {
        loading(false);
        return alert.error(intl["geocode_impossible_to_fetch"]);
    }
    // console.log(result)
    onEdit({ ...condominium, coordinates: geoJsonPointFromCoordinates(result[0])! })
    alert.info(intl["geolocated!"])
    loading(false);
}

export function hasChanges(state: CondominiumViewState, condominium: Condominium): boolean {
    const c1 = state.condominium;

    // console.log(JSON.stringify(c1, null, 2));
    // console.log(JSON.stringify(condominium, null, 2));

    return !compare(state.condominium, condominium)
}

export function parentSubCondominiumId(immobileUuid: string, condominium: Condominium): string | undefined {
    if (condominium.children.length == 0) return undefined;
    const subConodminiums = condominium.children.map((sc) => ({ sc_id: sc.node_id, children_uuids: sc.children.map(i => i.node_id) }))
    return first(subConodminiums.filter((sc) => sc.children_uuids.includes(immobileUuid)))?.sc_id
}

function compare(a: CondominiumNode, b: CondominiumNode): boolean {
    if (!(a?.type && b?.type)) return true;
    if (a?.type !== b?.type) return false;

    const ac = a.children.filter(c => c.type !== "METER" && c.type !== "GATEWAY");
    const bc = b.children.filter(c => c.type !== "METER" && c.type !== "GATEWAY");
    if (ac.length !== bc.length) return false;

    switch (a.type) {
        case "CONDOMINIUM": return JSON.stringify(a.data) === JSON.stringify((b as Condominium).data) && ac.map((c, i) => compare(c, bc[i])).reduce((a, b) => a && b, true)
        case "IMMOBILE": return JSON.stringify(a.data) === JSON.stringify((b as Condominium).data) && ac.map((c, i) => compare(c, bc[i])).reduce((a, b) => a && b, true)
        case "SUBCONDOMINIUM": return JSON.stringify(a.data) === JSON.stringify((b as Condominium).data) && ac.map((c, i) => compare(c, bc[i])).reduce((a, b) => a && b, true)
        default: return true
    }

}

export function validateCondominium(state: CondominiumNode, intl: Record<string, string>): string[] {
    let errors: string[] = [];

    const addPrefix = (cn: any) => `${intl[cn.type] ?? cn.type} ${intl[cn?.data?.name ?? ""] ?? cn?.data?.name ?? ""}: `

    switch (state.type) {
        case "CONDOMINIUM":
            if (!state.data.address || state.data.address == "") errors.push(addPrefix(state) + intl["address_should_be_present"])
            if (!state.data.cond_name || state.data.cond_name == "") errors.push(addPrefix(state) + intl["name_should_be_present"])
            if (!state.data.city || state.data.city == "") errors.push(addPrefix(state) + intl["city_should_be_present"])
            if (!state.data.postalCode || state.data.postalCode == "") errors.push(addPrefix(state) + intl["zip_should_be_present"])
            if (!state.data.province || state.data.province == "") errors.push(addPrefix(state) + intl["province_should_be_present"])
            break;
        case "SUBCONDOMINIUM":
            if (!state.data.address || state.data.address == "") errors.push(addPrefix(state) + intl["address_should_be_present"])
            if (!state.data.name || state.data.name == "") errors.push(addPrefix(state) + intl["name_should_be_present"])
            // if(!state.data.number || state.data.number == "") errors.push(addPrefix(state) + intl["number_should_be_present"])
            // if(!state.data.stair || state.data.stair == "") errors.push(addPrefix(state) + intl["stair_should_be_present"])
            break;
        case "IMMOBILE":
            if (!state.data.floor || state.data.floor as string == "") errors.push(addPrefix(state) + intl["floor_should_be_present"])
            if (!state.data.name || state.data.name == "") errors.push(addPrefix(state) + intl["immoblie_name_should_be_present"])
            if (!state.tenant.lastName || state.tenant.lastName == "") errors.push(addPrefix(state) + intl["imm_last_name_should_be_present"])
            break
        default: break
    }

    return [...errors, ...state.children.flatMap((c) => validateCondominium(c, intl))]
}


export function parseCondominiumRoute(route: string): { id?: string, node_id?: string } {
    console.log(route);
    const parts = route.split("/");
    let ret: { id?: string, node_id?: string } = {};
    if (parts[1] !== "condominiums") return ret;
    if (parts.length > 2) ret["id"] = parts[2];
    if (parts.length > 3 && parts[3] === "node_id") ret["node_id"] = parts[4];
    return ret;
}


export function emptyCondominiumViewState(condominium: Condominium): CondominiumViewState {
    return {
        condominium: condominium,
        selectedNode: condominium,
        state: "show",
        expandedNodesIds: [],
        prevNode: undefined
    };
}

export function toTreeModel(condominium: Condominium): TreeModel {
    return toTreeModelN(condominium);
}

function toTreeModelN(node: CondominiumNode): TreeModel {
    return {
        id: node.node_id,
        children: node.children.map(toTreeModelN),
        expanded: false
    };
}

export function nodeForId(node: CondominiumNode, id: string): CondominiumNode | undefined {
    if (node.node_id === id) return node;
    if ((node?.children?.length ?? 0) === 0) return undefined;
    for (const n of node.children) {
        const r = nodeForId(n, id);
        if (r) return r;
    }
    return undefined;
}

export function updateCondominium(condominium: CondominiumNode, newNode: CondominiumNode): CondominiumNode | undefined {
    if (condominium.node_id === newNode.node_id) return newNode;
    if (condominium.children.length === 0) return undefined;
    for (let i = 0; i < condominium.children.length; i++) {
        const r = updateCondominium(condominium.children[i], newNode);
        if (r) {
            const cl = JSON.parse(JSON.stringify(condominium));
            cl.children[i] = r;
            return cl;
        };
    }

    return undefined;
}

export function deleteNode(condominium: CondominiumNode, newNode: CondominiumNode): CondominiumNode | undefined {
    if (condominium.node_id === newNode.node_id) return undefined;
    if (condominium.children.length === 0) return condominium;
    const cl = JSON.parse(JSON.stringify(condominium));
    for (let i = 0; i < condominium.children.length; i++) {
        const r = deleteNode(condominium.children[i], newNode);
        cl.children[i] = r;
    }
    // @ts-ignore
    cl.children = cl.children.filter((c: any) => c !== undefined);
    return cl;

}

export function availableChildren(cn: CondominiumNode): CondominiumNodeType[] {
    switch (cn.type) {
        case "METER": return [];
        case "GATEWAY": return [];
        case "CONDOMINIUM": return ["SUBCONDOMINIUM", "METER", "GATEWAY"];
        case "SUBCONDOMINIUM": return ["IMMOBILE", "METER", "GATEWAY"];
        case "IMMOBILE": return ["METER", "GATEWAY"];
    }
}

export function moveUp(condominium: CondominiumNode & { type: "CONDOMINIUM" }, subCondominiumId: string, immobileId?: string): CondominiumNode {
    if (!immobileId) {
        // We are moving sub condominium
        const scs = condominium.children;
        if (scs.length <= 1) return condominium;
        const idsAndPositions = scs.reduce((acc: any, x, i) => { acc[x.id!.toString()] = i; return acc }, {})

        if (idsAndPositions[subCondominiumId] == 0) return condominium;

        const tmp = scs[idsAndPositions[subCondominiumId] - 1]
        scs[idsAndPositions[subCondominiumId] - 1] = scs[idsAndPositions[subCondominiumId]]
        scs[idsAndPositions[subCondominiumId]] = tmp;

        return { ...condominium, children: scs }
    } else {
        const [sc] = condominium.children.filter((n) => n.node_id == subCondominiumId);
        const imms = sc.children;
        if (imms.length <= 1) return condominium;
        const idsAndPositions = imms.reduce((acc: any, x, i) => { acc[x.id!.toString()] = i; return acc }, {})
        if (idsAndPositions[immobileId] == 0) return condominium;

        const tmp = imms[idsAndPositions[immobileId] - 1]
        imms[idsAndPositions[immobileId] - 1] = imms[idsAndPositions[immobileId]]
        imms[idsAndPositions[immobileId]] = tmp;

        sc.children = imms;

        return updateCondominium(condominium, sc)!;

    }
    return condominium
}

export function emptyCondominiumNodeForType(cnt: CondominiumNodeType, intl: Record<string, string>): CondominiumNode | undefined {
    const uniqueId: () => string = () => `${v1()}`;
    switch (cnt) {
        case "SUBCONDOMINIUM": return {
            type: "SUBCONDOMINIUM",
            path_url: "",
            node_id: uniqueId(),
            availableTabs: ["data", "readings", "consumption", "alarms", "self_reading"],
            children: [],
            data: {
                stair: "",
                name: "",
                address: "",
                number: "",
            }
        };
        case "IMMOBILE": return {
            type: "IMMOBILE",
            path_url: "",
            node_id: uniqueId(),
            availableTabs: ["data", "readings", "consumption", "alarms", "self_reading", "notifications", "note"],
            children: [],
            data: {
                floor: "Piano Terra",
                location: "",
                name: "",
                flatNumber: "",
                identifier: "",
            },
            tenant: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                fax: "",
                vatNumber: "",
            },
            owner: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                fax: "",
                vatNumber: "",
            }
        };
        case "METER": return {
            ...emptyMeter(),
            type: "METER",
            path_url: "",
            node_id: uniqueId(),
            availableTabs: ["data", "readings", "consumption", "alarms", "self_reading", "notifications"],
            children: [],
        };
        case "GATEWAY": return {
            ...emptyGateway(),
            type: "GATEWAY",
            path_url: "",
            node_id: uniqueId(),
            availableTabs: ["data", "readings", "meter_whitelist", "consumption", "alarms"],
            children: [],
        };
    }
}

export type CondominiumViewState = {
    selectedNode: CondominiumNode
    condominium: CondominiumNode
    state: "show" | "add_meter" | "add_gateway",
    expandedNodesIds: string[],
    prevNode: CondominiumNode | undefined
}

// this is used to know if something has changed its order inside conodminium
export function orderHashForCondominium(c: CondominiumNode) {
    return c.children.flatMap((sc: CondominiumNode) => sc.children.map((imm: CondominiumNode) => imm.id)).join("")
}
