import React, { useState } from "react";
import styled from "styled-components";
import { postWithoutToken } from "repsitory/generic_repository";
import { history } from "index";
import { LoaderComponent } from "components/loading_widget";
import { useIntl } from "react-intl";

const ForgotPassword: React.FC = () => {
  const intl = useIntl();
  const [username, setUsername] = useState("");
  const [isSuccess, setIsSuccess] = useState(false); // Track success state
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handlePasswordRecovery = async () => {
    setIsLoading(true); // Show loader before the request
    const body = {
      email: username, // Data for password recovery
    };

    const url = "/api/v1/user/send_reset_password"; // Endpoint for password recovery

    try {
      const response = await postWithoutToken(body, url); // Send request

      if (response === "success") {
        setIsSuccess(true); // On success, show success message
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false); // Hide loader after request completion
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handlePasswordRecovery();
  };

  return (
    <Container>
      <ForgotPasswordWrapper>
        {isLoading && (
          <LoaderWrapper>
            <LoaderComponent />
          </LoaderWrapper>
        )}
        <Logo>SWARM</Logo>
        <Heading>
          {isSuccess
            ? intl.messages["chek_mail"]
            : intl.messages["forgot_password"]}
        </Heading>

        {isSuccess ? (
          <>
            <InfoText>{intl.messages["reset_mail"]}</InfoText>
            <BackToLoginButton onClick={() => history.push("/login")}>
              {intl.messages["back_to_login"]}
            </BackToLoginButton>
          </>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            <ResetButton disabled={!username} type="submit">
              {intl.messages["reset_password"]}
            </ResetButton>
            <BackToLoginButton onClick={() => history.push("/login")}>
              {intl.messages["back_to_login"]}
            </BackToLoginButton>
          </Form>
        )}
      </ForgotPasswordWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #34495e; /* Dark blue background */
  margin: 0;
`;

const ForgotPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Important for centering loader inside the login box */
  width: 480px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 30px;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 20px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the loader */
  z-index: 10; /* Ensure the loader is above other content */
`;

const Logo = styled.h1`
  font-size: 40px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: #3498db; /* Blue color */
  margin-bottom: 50px;
  text-align: center;
  letter-spacing: 2px;
  animation: pulse 2s ease-in-out infinite; /* Applying the pulse animation with a slower duration */

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05); /* Reduced size increase to 5% */
      opacity: 0.9; /* Reduced opacity change for a softer effect */
    }
    100% {
      transform: scale(1); /* Restoring the original size */
      opacity: 1; /* Restoring the original opacity */
    }
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  letter-spacing: 1px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: #2980b9;
  }
`;

const BackToLoginButton = styled.button`
  width: 100%;
  padding: 14px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #34495e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #3498db;
  }
`;

const ResetButton = styled.button`
  width: 100%;
  padding: 14px;

  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #34495e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #3498db;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
    color: #7f8c8d;
  }
`;

const InfoText = styled.p`
  margin-top: 20px;
  font-size: 14px;
  color: #555;
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0; /* Light gray background for the info section */
  border-radius: 10px;
  width: 100%;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
`;

export default ForgotPassword;
