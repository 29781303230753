import React, { useEffect, useState } from "react";
import {
  fetchPaginatedCollection,
  remoteFetchAttributes,
} from "repsitory/generic_repository";
import { history } from "index";
import { TrashIcon, EyeIcon } from "components/icons";
import styled, { keyframes } from "styled-components";
import { gray } from "../../utils/colors";
import { useIntl } from "react-intl";
import { H4 } from "./text";
import { AsyncSelect } from "./select";
import ReactPaginate from "react-paginate";

const ITEMS_PER_PAGE = 10;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const SkeletonRow = styled.tr`
  td {
    padding: 12px;
    border-bottom: 1px solid #eee;
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 100%;
    animation: ${shimmer} 1.5s linear infinite;
    height: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
  background: #fff;
`;

const StyledTh = styled.th`
  background: #f7f7f7;
  padding: 12px;
  border-bottom: 2px solid #ddd;
  text-align: left;
  font-weight: 600;
`;

const StyledTd = styled.td`
  padding: 12px;
  border-bottom: 1px solid #eee;
`;

const RemoveButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    background: #c0392b;
  }
`;

type AsyncIdProps<T> = {
  baseUrl: string;
  valueMapper: (t: T) => string;
  idExtractor: (t: any) => any;
  onIdsSelected: (a: any[]) => void;
  selectedIds: any[];
  filterName: string;
  placeholder: string;
  label?: string;
  isMulti?: boolean;
  enabled?: boolean;
  style?: any;
};

export function MyAsyncSelectIdsFromCrud(props: AsyncIdProps<any>) {
   const intl = useIntl();
  const [localIds, setLocalIds] = useState<any[]>(props.selectedIds);

  const [loadedData, setLoadedData] = useState<{ [key: string]: any }>({});
  const [searchKey, setSearchKey] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLocalIds(props.selectedIds);
  }, [props.selectedIds]);

  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const pageIds = localIds.slice(startIndex, endIndex);

  const missingIds = pageIds.filter((id) => loadedData[id] === undefined);

  useEffect(() => {
    console.log("debugStartIndex", startIndex);
    console.log("debugEndIndex", endIndex);
    console.log("debugLocalIds", localIds);
    console.log("debugPageIds", pageIds);
    console.log("debugLoadedData", loadedData);
    console.log("debugmissingIds", missingIds);
  }, [startIndex, endIndex, localIds, pageIds, loadedData, missingIds]);

  useEffect(() => {
    if (missingIds.length > 0) {
      setIsLoading(true);
      (async () => {
        try {
          const results = await Promise.all(
            missingIds.map((id) =>
              remoteFetchAttributes(`${props.baseUrl}/${id}`)
            )
          );
          const validResults = results.filter((res) => typeof res !== "string");

          setLoadedData((prev) => {
            const newData = { ...prev };
            missingIds.forEach((id, idx) => {
              newData[id] = validResults[idx];
            });
            return newData;
          });
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [currentPage, localIds.join(","), props.baseUrl, missingIds.join(",")]);

  useEffect(() => {
    const maxPage = Math.ceil(localIds.length / ITEMS_PER_PAGE) - 1;
    if (currentPage > maxPage) {
      setCurrentPage(maxPage >= 0 ? maxPage : 0);
    }
  }, [localIds, currentPage]);

  const paginatedOptions = pageIds.map((id) => loadedData[id]);

  const handleRemove = (id: any) => {
    const updatedIds = localIds.filter((existingId) => existingId !== id);
    setLocalIds(updatedIds);
    props.onIdsSelected(updatedIds);

    setLoadedData((prev) => {
      const newData = { ...prev };
      delete newData[id];
      return newData;
    });
  };

  const handleSearchSelectChange = (items: any[]) => {
    if (!items || items.length === 0) return;
    const newItem = items[0];
    const newId = props.idExtractor(newItem);

    if (loadedData[newId] !== undefined) {
      setSearchKey((prev) => prev + 1);
      return;
    }

    setLoadedData((prev) => ({ ...prev, [newId]: newItem }));
    // Обновляем порядок id
    const updatedIds = [...localIds, newId];
    setLocalIds(updatedIds);
    props.onIdsSelected(updatedIds);
    setSearchKey((prev) => prev + 1);

    const lastPage = Math.ceil(updatedIds.length / ITEMS_PER_PAGE) - 1;
    setCurrentPage(lastPage);
  };

  return (
    <Container style={props.style}>
      {props.label && <H4>{props.label}</H4>}
      <AsyncSelect
        key={searchKey}
        labelStyle={{ marginBottom: 8 }}
        fetchOptions={async (partialInput) => {
          const data = await fetchPaginatedCollection<any>(
            `${props.baseUrl}?${props.filterName}=${partialInput}`
          )(1, 8);
          return typeof data === "string"
            ? []
            : data.sublist.map((item) => ({ ...item, name: item.name }));
        }}
        valueMapper={(t) => t.name}
        isMulti={false}
        selectedOptions={[]}
        placeholder={props.placeholder}
        enabled
        onChange={handleSearchSelectChange}
      />
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>{intl.messages["condominium"]}</StyledTh>
            <StyledTh>{intl.messages["province"]}</StyledTh>
            <StyledTh>{intl.messages["city"]}</StyledTh>
            <StyledTh>{intl.messages["address"]}</StyledTh>
            <StyledTh>{intl.messages["actions"]}</StyledTh>
          </tr>
        </thead>
        <tbody>
          {isLoading && missingIds.length > 0
            ? Array.from({ length: ITEMS_PER_PAGE }).map((_, idx) => (
                <SkeletonRow key={idx}>
                  <td colSpan={5}>&nbsp;</td>
                </SkeletonRow>
              ))
            : paginatedOptions.every((item) => item !== undefined) &&
              paginatedOptions.map((item) => (
                <tr key={props.idExtractor(item)}>
                  <StyledTd>{props.valueMapper(item)}</StyledTd>
                  <StyledTd>{item.province || "N/A"}</StyledTd>
                  <StyledTd>{item.city || "N/A"}</StyledTd>
                  <StyledTd>{item.address || "N/A"}</StyledTd>
                  <StyledTd>
                    
                    <button
                      className={"icon-button"}
                      onClick={() => handleRemove(props.idExtractor(item))}
                    >
                      <TrashIcon />
                    </button>
                  </StyledTd>
                </tr>
              ))}
        </tbody>
      </StyledTable>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(localIds.length / ITEMS_PER_PAGE)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        forcePage={currentPage}
        onPageChange={({ selected }) => setCurrentPage(selected)}
        containerClassName={"d-flex flex-row m-0 p-0"}
        activeClassName={"bold ml-2 mr-2"}
        pageClassName={"text ml-2 mr-2"}
        previousClassName={"text ml-2 mr-2"}
        nextClassName={"text ml-2 mr-2"}
      />
    </Container>
  );
}
