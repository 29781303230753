import React, { useMemo, useState } from "react";
import { SortableTableView } from "../../../components/barbagli/sortable_table_view";
import { fetchPaginatedCollection } from "../../../repsitory/generic_repository";
import { fallbackMapCenter, history } from "../../../index";

import { Condominium } from "../../../models/barbagli/condominium";
import { fromJson } from "../../../repsitory/barbagli/condominium_repository";
import { PaginatedList } from "../../../components/paginated_list/paginated_sublist";
import { useIntl } from "react-intl";
import {
  OutlineButton,
  PrimaryButton,
} from "../../../components/barbagli/common";
import { FilterParam, OrderParam } from "../../../models/barbagli/filter_order";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { deepCopy } from "../../../utils/deep_copy";
import { showFileUploader } from "components/file_uploader/file_uploader_component";
import { translate } from "utils/intl";
import { useLocation } from "react-router-dom";
import { TypeAuthorized } from "components/authorized";

export function CondominiumIndex() {
  const [order, setOrder] = useState<OrderParam>({
    column: "inserted_at",
    order: "desc",
  });
  const s = useLocation();
  const query = new URLSearchParams(s.search);
  const dashboardValue = query.get("dashboard");
  const [filter, setFilter] = useState<FilterParam[]>(
    dashboardValue ? [{ column: "dashboard", value: dashboardValue }] : []
  );
  const intl = useIntl();
  const translated = useMemo(() => translate(intl.messages as any), [intl]);

  return (
    <div className="d-flex flex-column">
      <SearchBar
        filters={filter}
        avaliableFilters={["name", "address", "province"]}
        filterNamePostProcessor={(id) =>
          intl.messages[`cond_${id}`]?.toString() ?? id
        }
        onFiltersChange={setFilter}
      />
      <SortableTableView
        perPage={15}
        key={JSON.stringify(filter)}
        fetchCollection={async (page, perPage) => {
          const data = await fetchPaginatedCollection<any>(
            `/api/v1/condominium?sort=${order.column}&order=${
              order.order
            }${filter.map((f) => `&${f.column}=${f.value}`).join("")}`
          )(page, perPage);
          if (typeof data === "string") return data;
          return {
            ...data,
            sublist: data.sublist.map(fromJson),
          } as PaginatedList<Condominium>;
        }}
        onTap={(item: Condominium | string) => {
          // console.log(item)
          if (typeof item !== "string")
          {
            debugger;
            history.push(
              `/condominiums/${item.data.cond_name ?? item.id}`,
              item
            );
          }
        }}
        itemsPreFormatter={(c: Condominium) => {
          const data = deepCopy(c.data);
          delete data["companyName"];
          return { ...data, insertedAt: c.insertedAt, partner: c.partners };
        }}
        order={order}
        translated
        onOrder={setOrder}
        orderableColumns={["inserted_at", "address", "province"]}
      />
      <div className="d-flex flex-row-reverse">
        <TypeAuthorized
          user_types={[
            "admin",
            "company-admin",
            "partner-admin",
            "installer",
            "mnd-user",
          ]}
        >
          <OutlineButton
            style={{ marginLeft: 8 }}
            onClick={() => {
              showFileUploader("/api/v1/condominium/import");
            }}
          >
            {translated("Create from Excel")}
          </OutlineButton>
          <PrimaryButton
            onClick={() => {
              history.push("/condominiums/new", null);
            }}
          >
            {intl.messages["add_condomium"]}
          </PrimaryButton>
        </TypeAuthorized>
      </div>
    </div>
  );
}
