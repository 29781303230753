import React, { useMemo, useState } from "react"
import { useIntl } from "react-intl";
import { Column, OutlineButton, PrimaryButton, Row } from "../../../components/barbagli/common";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { SortableTableView } from "../../../components/barbagli/sortable_table_view";
import { TabView } from "../../../components/barbagli/tab_view";
import { FilterParam, OrderParam } from "../../../models/barbagli/filter_order";
import { Attributes, emptyMeter, Meter } from "../../../models/meter";
import { fetchPaginatedCollection } from "../../../repsitory/generic_repository";
import { history } from "../../../index";
import { deepCopy } from "../../../utils/deep_copy";
import { and, BooleanFilter, Condition, EventFilter, or } from "../../data_explorer_page/ast/ast";
import { showExportFileDialog } from "../../../components/export_file_dialog";
import { DatePickerInput } from "../../../components/datepicker";
import moment from "moment";
import { showReadingMetaModal } from "./reading_meta_modal";
import { H3, H4 } from "components/barbagli/text";


/*
    Dex filters inserted here will be combined in AND with a filter for date and a filter for type selected in this view
*/
type Props = {
    gateway_id?: number
    dexFilters?: BooleanFilter
    tabHidden?: boolean
    from?: Date,
    to?: Date
}

type MetersIndexState = {
    selected_tab:
    | "RIPARTITORE"
    | "AF"
    | "ACS"
    | "CALORIE"
    | "FRIGORIE",
    isSingleDate: boolean
}

export function ReadingsIndex(props: Props) {
    const [state, setState] = useState<MetersIndexState>({ selected_tab: "AF", isSingleDate: true });
    if (props.tabHidden) return <ViewForState state={state} setState={setState} {...props} />

    return <div>
        <TabView
            children={[
                ["AF / ACS", <ViewForState tab={"AFACS"} state={state} setState={setState} {...props} />],
                ["RIPARTITORE", <ViewForState tab={"RIPARTITORE"} state={state} setState={setState}{...props} />],
                ["CALORIE / FRIGORIE", <ViewForState tab={"CALORIE / FRIGORIE"} state={state} setState={setState}{...props} />],
            ]}
        />


    </div>
}

function ViewForState(props: { tab?: string, state: MetersIndexState, gateway_id?: number, setState: React.Dispatch<React.SetStateAction<MetersIndexState>>, dexFilters?: BooleanFilter, from?: Date, to?: Date },) {
    const [order, setOrder] = useState<OrderParam>({ column: "", order: "asc" });
    const [date, setDate] = useState<Date>((() => {
        if (props.from) return props.from;
        let d = new Date();
        d.setDate(d.getDate() - 7);
        return d;
    })());
    const [isSingleDate, setSigleDate] = [props.state.isSingleDate, (v: boolean) => props.setState({ ...props.state, isSingleDate: v })];
    const [dateBefore, setDateBefore] = useState<Date>(props.to ?? new Date());
    const intl = useIntl();
    return <div className="d-flex flex-column">
        <Row>
            {!JSON.stringify(props.dexFilters).toLowerCase().includes("gateway") && <Column style={{ marginRight: 16 }}>
                <H4>{intl.messages["single_day"]}</H4>
                <input type="checkbox" checked={isSingleDate} onChange={(e) => {
                    setDateBefore(new Date())
                    return setSigleDate(e.target.checked);
                }} ></input>
            </Column>}
            {!isSingleDate && <DatePickerInput style={{ marginBottom: 12, flexGrow: 1, marginRight: 2 }} value={date} onChange={setDate} enabled label={intl.messages["reading_date"].toString()} />}
            <DatePickerInput style={{ marginBottom: 12, flexGrow: 1, marginLeft: 2 }} value={dateBefore} onChange={setDateBefore} enabled label={intl.messages["reading_date_before"].toString()} />

        </Row>
        <SortableTableView
            key={JSON.stringify([{ value: props.tab, column: "type" }, date.toISOString(), dateBefore.toISOString(), isSingleDate])}
            perPage={250}
            rowStyle={rowStyle(isSingleDate, dateBefore)}
            permutationKeyPostfix={props.tab}
            fetchCollection={
                fetchPaginatedCollection<any>(
                    `/api/v1/events?q=${encodeURIComponent(JSON.stringify(toEventFilter(
                        date, dateBefore, props.tab, props.dexFilters, isSingleDate
                    )))}${order.column != "" ? `&sort=${order.column}&order=${order.order}` : ""}`, undefined, (items) => {
                        return items.map((i) => {
                            const errors = i?.meta?.errors ?? {};
                            Object.keys(errors).forEach(key => { if (errors[key] == 0) delete errors[key]; })
                            return { ...i, errors: Object.keys(errors).length > 0 ? Object.keys(errors) : "--" }
                        })
                    })
            }
            onTap={(c) => showReadingMetaModal({ intl: intl.messages as any, content: c.meta })}
            translated
            itemsPreFormatter={(c: any) => {
                const cc: any = deepCopy(c);
                delete cc["meta"]
                delete cc["alarm_opened_at"]
                delete cc["id"];
                delete cc["immobile_id"];
                delete cc["sub_condominium_id"];
                delete cc["condominium_id"];
                delete cc["list_groups"];
                delete cc["meter_id"];


                if (props.tab?.includes("CALORIE") || cc["measure_unit"] === "watt_hour" || (cc["type"] ?? "").includes("CALORIE")) {
                    cc["measure_unit"] = "kWh"
                    for (const k of Object.keys(cc["values"] ?? {})) {
                        cc["values"][k] = cc["values"][k] / 1000
                    }
                }

                cc["values"] = Object.values(cc["values"] ?? []).reverse().map((it: any) => (Math.round((it + Number.EPSILON) * 1000) / 1000)).join("; ")
                return cc;
            }}
            order={order}
            onOrder={setOrder}
            orderableColumns={[
                "read_time",
                "condominium",
                "sub_condominium",
                "immobile",
                "floor",
                "meter_identifier",
                "internal"
            ]}

        />
        <div className="d-flex flex-row-reverse">
            <OutlineButton style={{ marginTop: 10 }} onClick={() => showExportFileDialog(intl.messages as any, toEventFilterExport(date, dateBefore, props.tab, props.dexFilters, isSingleDate))}>
                {intl.messages["export_readings"]}
            </OutlineButton>
        </div>
    </div>
}

function toEventFilter(fromDate: Date, dateBefore: Date, type: string | undefined, booleanFilter: BooleanFilter | undefined, isSingleDate: boolean): EventFilter {


    const type_filter: BooleanFilter = type ? {
        type: "CONDITION", name: "type", value: type
    } : true;

    // const type_filter: BooleanFilter = or({
    //     type: "CONDITION", name: "type", value: "CALORIE"
    // },or({
    //     type: "CONDITION", name: "type", value: "AFACS"
    // }, {
    //     type: "CONDITION", name: "type", value: "RIPARTITORE"
    // }));


    const fromDateFilter: BooleanFilter = isSingleDate ? true : {
        type: "CONDITION", name: "date_greater", value: fromDate.toISOString()
    }

    const dateBeforeFilter: BooleanFilter = {
        type: "CONDITION", name: "date_smaller", value: dateBefore.toISOString()
    }

    const fiter = and(and(fromDateFilter, type_filter), dateBeforeFilter);

    return {
        type: "FILTER",
        source: isSingleDate ? "cumulative_meter_readings" : "meter_readings",
        filter: booleanFilter ? and(fiter, booleanFilter) : fiter
    }

}


function toEventFilterExport(fromDate: Date, dateBefore: Date, type: string | undefined, booleanFilter: BooleanFilter | undefined, isSingleDate: boolean): EventFilter {


    const type_filter: BooleanFilter = or({
        type: "CONDITION", name: "type", value: "CALORIE"
    }, or({
        type: "CONDITION", name: "type", value: "AFACS"
    }, {
        type: "CONDITION", name: "type", value: "RIPARTITORE"
    }));

    const fromDateFilter: BooleanFilter = isSingleDate ? true : {
        type: "CONDITION", name: "date_greater", value: fromDate.toISOString()
    }

    const dateBeforeFilter: Condition = {
        type: "CONDITION", name: "date_smaller", value: dateBefore.toISOString()
    }

    const fiter = and(fromDateFilter, and(dateBeforeFilter, type_filter));
    return {
        type: "FILTER",
        source: isSingleDate ? "cumulative_meter_readings" : "meter_readings",
        filter: booleanFilter ? and(fiter, booleanFilter) : fiter
    }
}

const rowStyle = (isSingleDate: boolean, date: Date) => (item: any) => {
    if (!isSingleDate) return {};
    if (!item.read_time) return { backgroundColor: "rgba(168, 62, 50, 0.5)" };
    if (item?.alarm_opened_at) return { backgroundColor: "rgba(255, 169, 41, 0.5)" };
    if (item.has_errors) return { backgroundColor: "rgba(255, 238, 113, 0.5)" };
    if (moment(date).diff(moment(item.read_time), 'days') > 4) return { backgroundColor: "#34d8eb" };
    return {};
}